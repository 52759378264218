import { generateGUID } from "./store/CartSlice";
import masterCard from "../images/payments/MasterCard.jpg";
import amEx from "../images/payments/american-express.jpg";
import amazon from "../images/payments/amazon-pay.jpg";
import cash from "../images/payments/cash-app.jpg";
import visa from "../images/payments/visa.jpg";
import dinersClub from "../images/payments/diners-club.jpg";
import klarna from "../images/payments/klarna.jpg";
import imageOneOz from "../images/products/1oz-bottle.png";
import imageRusticPines from "../images/products/rustic-pines.jpg";

// Using require.context to import all images from the products directory
const importAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);

const apts01Images = importAll(require.context('../images/products/apts01', false, /\.(png|jpe?g|svg)$/));


export const websiteURL: string = 'http://localhost:3000/';
export const serverURL: string = process.env.REACT_APP_SERVER_URL || 'http://localhost:4242/';
export const stripeSecret: string = process.env.REACT_APP_STRIPE_SECRET || 'pk_test_51PiPaeLQfgD2l9pcAPVOF8KLGvXFEJAeQ1wo9rGSLHlOeZxUkfbERPgIkaZSiAJBwjPG8WKh3p6ZG6zouUN6HFoD00rG9vZejM';
export const shippingId: string = process.env.REACT_APP_SHIPPING_ID || 'shr_1PpWVhLQfgD2l9pc4ypW4UEl';
// export const discountId: string = 'none';
export const shippingRate: number = 4;

export interface Product {
    buyImage: string;
    category: string;
    description: string;
    guid: string;
    handcrafted: boolean;
    howTo: string;
    id: string;
    images: string[];
    ingredients: string[];
    keyIngredients: string[];
    productType: string;
    name: string;
    price: number;
    priceId: string;
    selectedSize: string;
    sizes: string[];
    sku: string;
    quantity: number;
}

export const defaultProduct: Product = {
    id: 'default',
    productType: 'default',
    name: 'default',
    price: 999.99,
    images: ['https://dummyimage.com/600x600/dee2e6/6c757d.jpg'],
    buyImage: 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg',
    guid: generateGUID(),
    sku: 'default',
    priceId: 'default',
    quantity: 1,
    description: 'default',
    ingredients: ['default'],
    howTo: 'default',
    keyIngredients: ['default'],
    handcrafted: true,
    sizes: [],
    selectedSize: '',
    category: 'default'
};

export const prod_QdLu2CBXXkO0LM: Product = {
    id: process.env.REACT_APP_PROD01_ID || 'prod_QdLu2CBXXkO0LM',
    productType: 'Beard Oil',
    name: 'Rustic Pines',
    price: 13.95,
    images: [imageOneOz],
    buyImage: imageRusticPines,
    guid: generateGUID(),
    sku: 'BO-SC-01',
    priceId: process.env.REACT_APP_PROD01_PRICE_ID || 'price_1Pm5CvLQfgD2l9pctNBQ6d1N',
    quantity: 1,
    description: 'Rustic Pines isn\'t just about scent—it\'s about bringing the essence of nature into your daily grooming routine. Our all-natural formula nourishes and softens your beard, leaving it feeling smooth, healthy, and subtly fragranced. Perfect for the modern man who appreciates the rugged charm of the wilderness.',
    ingredients: ['Sunflower Seed Oil', 'Jojoba Oil', 'Grapeseed Oil', 'Pumpkin Seed Oil', 'Virginian Cedarwood Oil', 'Scotch Pine Oil', 'Natural Fragrance'],
    howTo: 'Dispense a few drops of beard oil into your palm and rub your hands together. Massage the oil into your beard and the skin beneath. Finally, groom and style your beard as desired.',
    keyIngredients: ['sunflower', 'pine', 'cedar'],
    handcrafted: true,
    sizes: [],
    selectedSize: 'Rustic Pines',
    category: 'grooming'
};

export const prod_Qgsw9mnAPtbKEd: Product = {
    id: 'prod_Qgsw9mnAPtbKEd',
    productType: 'Beard Oil',
    name: 'Unscented',
    price: 12.95,
    images: [imageOneOz],
    buyImage: 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg',
    guid: generateGUID(),
    sku: 'BO-NS-01',
    priceId: 'price_1PpVAGLQfgD2l9pcI67WECFJ',
    quantity: 1,
    description: 'Our signature all-natural formula nourishes and softens your beard without any added fragrance, letting you enjoy the benefits of our expertly crafted blend without compromise. Perfect for the modern man who values simplicity and quality.',
    ingredients: ['Sunflower Seed Oil', 'Jojoba Oil', 'Grapeseed Oil', 'Pumpkin Seed Oil'],
    howTo: 'Dispense a few drops of beard oil into your palm and rub your hands together. Massage the oil into your beard and the skin beneath. Finally, groom and style your beard as desired.',
    keyIngredients: ['sunflower', 'jojoba', 'pumpkin'],
    handcrafted: true,
    sizes: [],
    selectedSize: 'Unscented',
    category: 'grooming'
}

export const prod_QqQjea7MxHsxqL: Product = {
    id: 'prod_QqQjea7MxHsxqL',
    productType: 'T-SHIRT',
    name: 'Embroidered Logo',
    price: 20.00,
    images: [apts01Images[0], apts01Images[1], apts01Images[2]],
    buyImage: apts01Images[0],
    guid: generateGUID(),
    sku: 'AP-TS-01',
    priceId: 'price_1PyjroLQfgD2l9pcRGz1RHiv',
    quantity: 1,
    description: 'Show your support for Heartland Grooming Co. with this soft-style tee. Crafted from 100% ring-spun cotton for a soft, durable fit, this tee features our company insignia elegantly embroidered on the left chest and our bold logo screen printed across the back. Perfect for the modern man who values quality and style, or simply anyone who wants to support our brand while looking suave.',
    ingredients: ['100% ring-spun cotton', 'Regular fit', 'Embroidered front logo', 'screen-printed back logo', 'This product is made on demand'],
    howTo: '',
    keyIngredients: [],
    handcrafted: false,
    sizes: ['S', 'M', 'L', 'XL', '2XL', '3XL'],
    selectedSize: '',
    category: 'apparel'
}

// Store all products in an array
// , prod_QqQjea7MxHsxqL
export const productsList: Product[] = [prod_QdLu2CBXXkO0LM, prod_Qgsw9mnAPtbKEd];

// Optionally, you can create a lookup function by ID
export const getProductById = (id: string): Product | undefined => {
    return productsList.find(product => product.id === id);
};

export const getProductBySku = (sku: string): Product | undefined => {
    return productsList.find(product => product.sku === sku);
};

export const paymentOptions = {
    visa: {
        alt: 'Visa',
        image: visa
    },
    mastercard: {
        alt: 'MasterCard',
        image: masterCard
    },
    amex: {
        alt: 'American Express',
        image: amEx
    },
    diners: {
        alt: 'Diners Club',
        image: dinersClub
    },
    amazon: {
        alt: 'Amazon Pay',
        image: amazon
    },
    cash: {
        alt: 'Cash App Pay',
        image: cash
    },
    klarna: {
        alt: 'Klarna Pay',
        image: klarna
    }
};
