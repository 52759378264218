import React from 'react'
import { shippingRate } from '../database'

export default function ShippingRateForm() {
    return (
        <div className="mt-3 mb-3">
            <h3 className="fw-bolder">Shipping Rate</h3>
            <input type="radio" name="shippingOption" value="standard" className="m-3" checked />
            USPS Ground Advantage - {shippingRate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (2-5 business days)
        </div>
    )
}
