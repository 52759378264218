import React from 'react'
import { shippingRate } from '../database';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { useStripe } from '@stripe/react-stripe-js';

interface SummaryProps {
    clientSecret: string | null;
    stateCode: string | null;
    salesTax: number;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    total: number;
}

const CheckoutSummary: React.FC<SummaryProps> = ({ clientSecret, stateCode, salesTax, total, setTotal }) => {
    const stripe = useStripe();
    const cart = useSelector((state: RootState) => state.cart.cart);
    const discount = 0 + useSelector((state: RootState) => state.cart.discount.amount);
    

    const cartTotal = cart.reduce((sum, item) => {
        return sum + item.price;
    }, 0);

    if (!stripe || !clientSecret) {
        return (
            <div>
                Something went wrong.
            </div>
        )
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (!paymentIntent) {
            return (
                <div>
                    Something went wrong.
                </div>
            )
        }
        setTotal(paymentIntent.amount);
    })

    return (
        <div>
            <div className="mb-3">
                <h3 className="fw-bolder">Summary</h3>
                <div className="card-body">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 border-0">
                            Items (x {cart.length})
                            {/* <span className=''>{payment}{cartTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span> */}
                            <span className=''>{cartTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 border-0">
                            Discount
                            <span className=''>{discount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                            Shipping
                            <span>{shippingRate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </li>
                        {(salesTax > 0) && (
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                Tax ({stateCode})
                                <span>{((total / 100) * salesTax).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                            </li>
                        )}
                        <hr />
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                            <strong>Total</strong>
                            <span><strong>{(total / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default CheckoutSummary;
